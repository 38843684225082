const users: Record<string, undefined | number> = {
  "": undefined,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
};

const lastAdded: Record<string, number> = {
  today: 1,
  onWeek: 7,
  onMonth: 30,
};

const bodyTypes: Record<string, string> = {
  boxtypedeliveryvan: "boxtypedeliveryvan",
  cabrio: "cabrio",
  clubbus: "clubbus",
  estatecar: "estatecar",
  estateminibusupto9seatsvan: "estateminibusupto9seatsvan",
  highandlongboxtypedeliveryvan: "highandlongboxtypedeliveryvan",
  highboxtypedeliveryvan: "highboxtypedeliveryvan",
  limousine: "limousine",
  longboxtypedeliveryvan: "longboxtypedeliveryvan",
  motorhomesandother: "motorhomesandother",
  offroad: "offroad",
  otherbus: "otherbus",
  othercar: "othercar",
  othervanupto7500: "othervanupto7500",
  smallcar: "smallcar",
  sportscar: "sportscar",
  stakebodyvan: "stakebodyvan",
  van: "van",
  vanmotorhome: "vanmotorhome",
};
const transmissions: Record<string, string> = {
  automatic: "automatic",
  manual: "manual",
  "semi-automatic": "semi-automatic",
  CVT: "CVT",
};

const fuelTypes: Record<string, string> = {
  Бензин: "petrol",
  Дизель: "diesel",
  Электричество: "electric",
  Гибрид: "hybrid",
  Водород: "hydrogen",
  "Природный газ": "natural gas",
};

const driveTypes: Record<string, string> = {
  "Передний привод": "front-wheel",
  "Задний привод": "rear-wheel",
  "Полный привод": "all-wheel",
};

const radius: string[] = ["10", "20", "30"];
const types: Record<string, string> = {
  all: "all",
  new: "new",
  withMileage: "withMileage",
};

export const marks = [
  "Volkswagen",
  "Opel",
  "Audi",
  "Other",
  "Ford",
  "BMW",
  "Mercedes-Benz",
  "Skoda",
  "Smart",
  "Renault",
  "Fiat",
  "Seat",
  "Nissan",
  "Hyundai",
  "Toyota",
  "Citroën",
  "Peugeot",
  "Kia",
  "Dacia",
  "Porsche",
  "MINI",
  "Mazda",
  "Jeep",
  "Cupra",
  "Volvo",
  "Lancia",
  "Honda",
  "Suzuki",
  "Mitsubishi",
  "Land Rover",
  "Alfa Romeo",
  "Tesla",
  "Chevrolet",
  "Subaru",
  "Jaguar",
  "Dodge",
  "Trabant",
  "Iveco",
  "Chrysler",
  "Abarth",
  "Saab",
  "Daihatsu",
  "Lada",
  "DS Automobiles",
  "Maserati",
  "MG",
  "Lamborghini",
  "Lexus",
  "Aixam",
  "Lynk&Co",
  "Isuzu",
  "Bentley",
  "Rover",
  "MAN",
  "Ssangyong",
  "Cadillac",
  "Polestar",
  "Ferrari",
  "Alpine",
  "Aston Martin",
  "Daewoo",
  "Ligier",
  "Hummer",
  "GWM",
  "Corvette",
  "Rolls-Royce",
  "SWM",
  "ALPINA",
  "Infiniti",
  "Piaggio",
  "Maxus",
  "BAIC",
  "Austin Healey",
  "DFSK",
  "INEOS",
  "GMC",
  "BYD",
  "Lotus",
  "McLaren",
  "AC",
  "Leapmotor",
  "Plymouth",
  "Pontiac",
  "Triumph",
  "Genesis",
  "Morgan",
  "ORA",
  "Wartburg",
  "Elaris",
  "Lincoln",
  "Microcar",
  "NSU",
  "Tata",
  "Borgward",
  "Aiways",
  "XPENG",
  "Maybach",
  "JAC",
  "Oldsmobile",
  "Seres",
  "Cobra",
  "Austin",
  "Chatenet",
  "e.GO",
  "DeTomaso",
  "Barkas",
  "Bugatti",
  "Buick",
  "XEV",
  "Fisker",
  "Donkervoort",
  "TECHART",
  "Westfalia",
  "Casalini",
  "NIO",
  "Westfield",
  "Grecav",
  "Lucid",
  "Microlino",
  "WEY",
  "TVR",
  "LEVC",
  "Wiesmann",
  "Brilliance",
  "Acura",
  "Talbot",
  "Zhidou",
  "Asia Motors",
];

export const models: Record<string, Record<string, string[]> | string[]> = {
  Volkswagen: {
    Популярные: [
      "Golf",
      "Polo",
      "Passat",
      "Tiguan",
      "Touareg",
      "Caddy",
      "Transporter",
      "Multivan",
    ],
    Caddy: ["Caddy", "Caddy Maxi"],
    Golf: ["Golf", "Golf Plus", "Golf Sportsvan"],
    Passat: ["Passat", "Passat Alltrack", "Passat CC", "Passat Variant"],
    T3: ["T3 Caravelle", "T3 Kombi", "T3 Multivan", "T3 other"],
    T4: [
      "T4 California",
      "T4 Caravelle",
      "T4 Kombi",
      "T4 Multivan",
      "T4 other",
    ],
    T5: [
      "T5 California",
      "T5 Caravelle",
      "T5 Kombi",
      "T5 Multivan",
      "T5 Shuttle",
      "T5 Transporter",
      "T5 other",
    ],
    T6: [
      "T6 California",
      "T6 Caravelle",
      "T6 Kombi",
      "T6 Multivan",
      "T6 Transporter",
      "T6 other",
    ],
    T7: ["T7 California", "T7 Multivan", "T7 Transporter", "T7 other"],
    Tiguan: ["Tiguan", "Tiguan Allspace"],
    Other: [
      "Amarok",
      "Arteon",
      "Beetle",
      "Bora",
      "Buggy",
      "CC",
      "Corrado",
      "Crafter",
      "Eos",
      "Fox",
      "ID. Buzz",
      "ID.3",
      "ID.4",
      "ID.5",
      "ID.7",
      "Iltis",
      "Jetta",
      "Karmann Ghia",
      "Käfer",
      "LT",
      "Lupo",
      "New Beetle",
      "Phaeton",
      "Routan",
      "Santana",
      "Scirocco",
      "Sharan",
      "T-Cross",
      "T-Roc",
      "T1",
      "T2",
      "Taigo",
      "Taro",
      "Tayron",
      "Touareg",
      "Touran",
      "Vento",
      "XL1",
      "up!",
    ],
  },
  Opel: {
    Популярные: [
      "Corsa",
      "Astra",
      "Insignia",
      "Zafira",
      "Mokka",
      "Grandland (X)",
      "Vectra",
      "Vivaro",
    ],
    Corsa: ["Corsa"],
    Astra: ["Astra"],
    Insignia: ["Insignia", "Insignia CT"],
    Zafira: ["Zafira", "Zafira Tourer", "Zafira Life"],
    Mokka: ["Mokka", "Mokka X", "Mokka-e"],
    Grandland: ["Grandland (X)"],
    Vectra: ["Vectra"],
    Vivaro: ["Vivaro"],
    Crossland: ["Crossland (X)"],
    Combo: ["Combo", "Combo Life", "Combo Electric"],
    Other: [
      "Adam",
      "Agila",
      "Meriva",
      "Movano",
      "Tigra",
      "Antara",
      "Signum",
      "Omega",
      "Kadett",
      "Karl",
      "Cascada",
      "Rekord",
      "Frontera",
      "Ascona",
      "Rocks-e",
      "Calibra",
      "GT",
      "Manta",
      "Senator",
      "Nova",
      "Ampera",
      "Commodore",
      "Speedster",
      "Campo",
      "Ampera-e",
      "Monterey",
      "Diplomat",
      "Sintra",
      "Monza",
      "Cavalier",
    ],
  },
  Audi: {
    Популярные: ["A4", "A6", "Q5", "A3", "Q3"],
    A: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8"],
    Q: [
      "Q2",
      "Q3",
      "Q4",
      "Q4 e-tron",
      "Q5",
      "Q6 e-tron",
      "Q7",
      "Q8",
      "Q8 e-tron",
    ],
    Allroad: ["A6 Allroad", "A4 Allroad"],
    RS: [
      "RS6",
      "RS4",
      "RS5",
      "RS7",
      "RS3",
      "RSQ8",
      "RSQ3",
      "RS e-tron GT",
      "RS2",
    ],
    S: ["S6", "S4", "S8", "S7", "S3", "S5", "S1", "S2"],
    SQ: ["SQ5", "SQ7", "SQ8", "SQ2", "SQ6 e-tron", "SQ8 e-tron"],
    "e-tron": [
      "e-tron",
      "Q4 e-tron",
      "Q8 e-tron",
      "e-tron GT",
      "A6 e-tron",
      "Q6 e-tron",
    ],
    TT: ["TT", "TTS", "TT RS"],
    Other: ["80", "100", "200", "quattro", "Cabriolet", "Coupé", "R8", "V8"],
  },
  Other: ["Other"],
  Ford: {
    Популярные: ["Focus", "Fiesta", "Kuga", "Mondeo", "Transit"],
    Transit: [
      "Transit",
      "Transit Custom",
      "Transit Connect",
      "Transit Courier",
    ],
    Tourneo: [
      "Tourneo",
      "Tourneo Custom",
      "Tourneo Connect",
      "Tourneo Courier",
      "Grand Tourneo",
    ],
    "C-Max": ["C-Max", "Grand C-Max"],
    "F-Series": ["F 100", "F 150", "F 250", "F 350"],
    Classic: [
      "Capri",
      "Thunderbird",
      "Probe",
      "Maverick",
      "Granada",
      "Cougar",
      "Fairlane",
      "Taunus",
      "Sierra",
      "Scorpio",
      "Orion",
      "Taurus",
      "Falcon",
    ],
    "SUV & Vans": [
      "Expedition",
      "Excursion",
      "Escape",
      "Econoline",
      "Windstar",
      "Flex",
    ],
    Other: [
      "Puma",
      "Ka/Ka+",
      "Ranger",
      "Raptor",
      "Galaxy",
      "EcoSport",
      "Mustang",
      "Mustang Mach-E",
      "Edge",
      "Explorer",
      "Escort",
      "GT",
      "Streetka",
      "Sportka",
      "Mercury",
      "Express",
      "Crown",
    ],
  },
  BMW: {
    Популярные: ["320", "520", "X5", "X3", "M3"],
    "1 Series": [
      "114",
      "116",
      "118",
      "120",
      "123",
      "125",
      "128",
      "130",
      "135",
      "M135",
      "M140i",
    ],
    "2 Series": [
      "218",
      "220",
      "225",
      "228",
      "230",
      "M235",
      "M240i",
      "2er Gran Coupé",
    ],
    "3 Series": [
      "316",
      "318",
      "320",
      "323",
      "324",
      "325",
      "328",
      "330",
      "335",
      "340",
      "M340i",
      "M340d",
    ],
    "4 Series": ["418", "420", "425", "428", "430", "435", "440", "M440"],
    "5 Series": [
      "518",
      "520",
      "523",
      "525",
      "528",
      "530",
      "535",
      "540",
      "545",
      "550",
      "M550",
    ],
    "6 Series": [
      "620 Gran Turismo",
      "628",
      "630",
      "635",
      "640",
      "645",
      "650",
      "M6",
    ],
    "7 Series": [
      "725",
      "728",
      "730",
      "732",
      "735",
      "740",
      "745",
      "750",
      "760",
      "M760",
    ],
    "8 Series": ["840", "850", "M8", "M850"],
    "X Series": [
      "X1",
      "X2",
      "X3",
      "X3 M",
      "X3 M40",
      "X3 M50",
      "X4",
      "X4 M",
      "X4 M40",
      "X5",
      "X5 M",
      "X5 M50",
      "X5 M60",
      "X6",
      "X6 M",
      "X6 M50",
      "X6 M60",
      "X7",
      "X7 M50",
      "X7 M60",
    ],
    "i Series": ["i3", "i4", "i5", "i7", "i8", "iX", "iX1", "iX2", "iX3"],
    "Z Series": ["Z1", "Z3", "Z3 M", "Z4", "Z4 M", "Z8"],
    "Gran Turismo": [
      "320 Gran Turismo",
      "330 Gran Turismo",
      "340 Gran Turismo",
      "530 Gran Turismo",
      "535 Gran Turismo",
      "550 Gran Turismo",
      "630 Gran Turismo",
      "640 Gran Turismo",
    ],
    "Gran Coupé": [
      "418 Gran Coupé",
      "420 Gran Coupé",
      "425 Gran Coupé",
      "428 Gran Coupé",
      "430 Gran Coupé",
      "435 Gran Coupé",
      "440 Gran Coupé",
      "650 Gran Coupé",
    ],
    "Active Tourer": [
      "214 Active Tourer",
      "216 Active Tourer",
      "218 Active Tourer",
      "220 Active Tourer",
      "225 Active Tourer",
    ],
    "Gran Tourer": ["216 Gran Tourer", "218 Gran Tourer", "220 Gran Tourer"],
    ActiveHybrid: [
      "ActiveHybrid 3",
      "ActiveHybrid 5",
      "ActiveHybrid 7",
      "ActiveHybrid X6",
    ],
    Other: ["2002", "524", "ActiveHybrid X6", "XM", "Z8", "Coupé"],
  },
  "Mercedes-Benz": {
    Популярные: ["C 200", "E 220", "GLC 300", "S 500", "Vito"],
    "A-Class": [
      "A 140",
      "A 150",
      "A 160",
      "A 170",
      "A 180",
      "A 190",
      "A 200",
      "A 210",
      "A 220",
      "A 250",
      "A 35 AMG",
      "A 45 AMG",
    ],
    "B-Class": ["B 150", "B 160", "B 170", "B 180", "B 200", "B 220", "B 250"],
    "C-Class": [
      "C 160",
      "C 180",
      "C 200",
      "C 220",
      "C 230",
      "C 240",
      "C 250",
      "C 270",
      "C 280",
      "C 300",
      "C 320",
      "C 350",
      "C 400",
      "C 43 AMG",
      "C 55 AMG",
      "C 63 AMG",
    ],
    "E-Class": [
      "E 200",
      "E 220",
      "E 230",
      "E 240",
      "E 250",
      "E 260",
      "E 270",
      "E 280",
      "E 290",
      "E 300",
      "E 320",
      "E 350",
      "E 400",
      "E 420",
      "E 430",
      "E 450",
      "E 500",
      "E 53 AMG",
      "E 55 AMG",
      "E 63 AMG",
    ],
    "S-Class": [
      "S 250",
      "S 280",
      "S 300",
      "S 320",
      "S 350",
      "S 400",
      "S 420",
      "S 430",
      "S 450",
      "S 500",
      "S 550",
      "S 560",
      "S 580",
      "S 600",
      "S 650",
      "S 680",
      "S 55 AMG",
      "S 63 AMG",
      "S 65 AMG",
    ],
    "CLA-Class": [
      "CLA 180",
      "CLA 200",
      "CLA 220",
      "CLA 250",
      "CLA 35 AMG",
      "CLA 45 AMG",
    ],
    "CLS-Class": [
      "CLS 220",
      "CLS 250",
      "CLS 280",
      "CLS 300",
      "CLS 320",
      "CLS 350",
      "CLS 400",
      "CLS 450",
      "CLS 500",
      "CLS 53 AMG",
      "CLS 55 AMG",
      "CLS 63 AMG",
    ],
    "SL-Class": [
      "SL 280",
      "SL 300",
      "SL 320",
      "SL 350",
      "SL 400",
      "SL 430",
      "SL 450",
      "SL 500",
      "SL 55 AMG",
      "SL 560",
      "SL 600",
      "SL 63 AMG",
      "SL 65 AMG",
    ],
    "CLK-Class": [
      "CLK 200",
      "CLK 220",
      "CLK 230",
      "CLK 240",
      "CLK 270",
      "CLK 280",
      "CLK 320",
      "CLK 350",
      "CLK 430",
      "CLK 500",
      "CLK 55 AMG",
      "CLK 63 AMG",
    ],
    "GLA-Class": [
      "GLA 180",
      "GLA 200",
      "GLA 220",
      "GLA 250",
      "GLA 35 AMG",
      "GLA 45 AMG",
    ],
    "GLB-Class": ["GLB 180", "GLB 200", "GLB 220", "GLB 250", "GLB 35 AMG"],
    "GLC-Class": [
      "GLC 200",
      "GLC 220",
      "GLC 250",
      "GLC 300",
      "GLC 350",
      "GLC 400",
      "GLC 43 AMG",
      "GLC 63 AMG",
    ],
    "GLE-Class": [
      "GLE 250",
      "GLE 300",
      "GLE 350",
      "GLE 400",
      "GLE 450",
      "GLE 500",
      "GLE 53 AMG",
      "GLE 63 AMG",
    ],
    "GLS-Class": [
      "GLS 350",
      "GLS 400",
      "GLS 450",
      "GLS 500",
      "GLS 580",
      "GLS 600",
      "GLS 63",
    ],
    "ML-Class": [
      "ML 230",
      "ML 250",
      "ML 270",
      "ML 280",
      "ML 300",
      "ML 320",
      "ML 350",
      "ML 400",
      "ML 420",
      "ML 430",
      "ML 450",
      "ML 500",
      "ML 55 AMG",
      "ML 63 AMG",
    ],
    "G-Class": [
      "G 230",
      "G 240",
      "G 250",
      "G 270",
      "G 280",
      "G 290",
      "G 300",
      "G 320",
      "G 350",
      "G 400",
      "G 450",
      "G 500",
      "G 55 AMG",
      "G 63 AMG",
      "G 65 AMG",
    ],
    "R-Class": ["R 280", "R 300", "R 320", "R 350", "R 500", "R 63 AMG"],
    "EQ-Series": [
      "EQA",
      "EQB",
      "EQC",
      "EQE",
      "EQE SUV",
      "EQS",
      "EQS SUV",
      "EQV",
    ],
    "SLK/SLC-Class": [
      "SLK 200",
      "SLK 230",
      "SLK 250",
      "SLK 280",
      "SLK 300",
      "SLK 320",
      "SLK 350",
      "SLK 55 AMG",
      "SLK 32 AMG",
      "SLC 180",
      "SLC 200",
      "SLC 250",
      "SLC 280",
      "SLC 300",
      "SLC 43 AMG",
    ],
    "AMG GT": ["AMG GT", "AMG GT S", "AMG GT R", "AMG GT C"],
    Commercial: [
      "Sprinter",
      "Vito",
      "Viano",
      "Citan",
      "T-Class",
      "Vaneo",
      "V 200",
      "V 220",
      "V 230",
      "V 250",
      "V 280",
      "V 300",
    ],
    Other: [
      "190",
      "200",
      "220",
      "230",
      "250",
      "260",
      "270",
      "280",
      "290",
      "300",
      "320",
      "350",
      "380",
      "400",
      "416",
      "420",
      "430",
      "450",
      "500",
      "560",
      "600",
      "CE 200",
      "CE 220",
      "CE 230",
      "CE 280",
      "CE 300",
      "CE 320",
      "CLC 160",
      "CLC 180",
      "CLC 200",
      "CLC 220",
      "CLC 230",
      "CLC 250",
      "CLC 350",
      "X 250",
      "X 350",
      "Vario",
      "SLR",
      "SLS AMG",
      "EQT",
      "E 50",
      "ActiveHybrid",
    ],
  },
  Skoda: {
    Популярные: ["Octavia", "Fabia", "Superb", "Kodiaq", "Karoq"],
    "Sedan/Hatchback": ["Octavia", "Fabia", "Superb", "Rapid", "Scala"],
    SUV: ["Kodiaq", "Karoq", "Kamiq", "Yeti", "Enyaq", "Elroq"],
    "Compact/City": ["Citigo", "Roomster", "Felicia", "Favorit"],
    Utility: ["Praktik", "Pick-up"],
    Classic: ["105", "120", "130"],
    Other: ["Other"],
  },
  Smart: ["ForTwo", "ForFour", "Other", "Roadster", "#1", "#3", "Crossblade"],
  Renault: {
    Популярные: ["Clio", "Twingo", "Megane", "Captur", "Kangoo"],
    "Sedan/Hatchback": [
      "Clio",
      "Megane",
      "Laguna",
      "Talisman",
      "Latitude",
      "Fluence",
    ],
    "SUV/Crossover": [
      "Captur",
      "Kadjar",
      "Koleos",
      "Arkana",
      "Austral",
      "Rafale",
      "Symbioz",
    ],
    Electric: ["ZOE", "Twizy", "Kangoo E-TECH"],
    MPV: [
      "Scenic",
      "Grand Scenic",
      "Espace",
      "Grand Espace",
      "Modus",
      "Grand Modus",
    ],
    "Van/Commercial": [
      "Kangoo",
      "Grand Kangoo",
      "Trafic",
      "Master",
      "Express",
      "Rapid",
      "Mascott",
      "Alaskan",
    ],
    Classic: [
      "R 4",
      "R 5",
      "R 6",
      "R 9",
      "R 11",
      "R 19",
      "R 20",
      "R 21",
      "R 30",
    ],
    "Coupe/Sport": [
      "Wind",
      "Coupe",
      "Alpine A110",
      "Alpine A310",
      "Alpine V6",
      "Avantime",
      "Vel Satis",
      "Fuego",
    ],
    Other: ["Other"],
  },
  Fiat: {
    Популярные: ["Panda", "500", "Punto", "Ducato", "Tipo"],
    "500 Series": [
      "500",
      "500C",
      "500X",
      "500L",
      "500L Cross",
      "500L Trekking",
      "500L Urban",
      "500L Living",
      "500L Wagon",
      "500S",
      "500e",
    ],
    "Panda Series": ["Panda", "New Panda"],
    "Punto Series": ["Punto", "Grande Punto", "Punto Evo"],
    "Sedan/Hatchback": [
      "Tipo",
      "Bravo",
      "Brava",
      "Stilo",
      "Linea",
      "Croma",
      "Tempra",
      "Regata",
      "Palio",
      "Albea",
      "Marea",
      "Uno",
    ],
    "Van/Commercial": [
      "Ducato",
      "Doblo",
      "Qubo",
      "Fiorino",
      "Scudo",
      "Talento",
      "Fullback",
      "Strada",
      "Ulysse",
    ],
    Classic: [
      "Seicento",
      "126",
      "600",
      "600e",
      "Cinquecento",
      "127",
      "131",
      "130",
      "Spider Europa",
      "Ritmo",
      "Topolino",
    ],
    "Sport/Coupe": ["124 Spider", "Coupe", "X 1/9", "Dino", "Barchetta"],
    MPV: ["Multipla", "Sedici", "Freemont", "Idea"],
    Other: ["Other"],
  },
  Seat: {
    Популярные: ["Ibiza", "Leon", "Arona", "Ateca", "Alhambra"],
    Hatchback: ["Ibiza", "Leon", "Mii", "Arosa"],
    "SUV/Crossover": ["Arona", "Ateca", "Tarraco"],
    Sedan: ["Toledo", "Exeo", "Cordoba", "Malaga"],
    MPV: ["Alhambra", "Altea"],
    "Van/Commercial": ["Inca", "Terra"],
    Classic: ["Marbella"],
    Other: ["Other"],
  },
  Nissan: {
    Популярные: ["Qashqai", "Micra", "Juke", "X-Trail", "Navara"],
    Hatchback: ["Micra", "Pixo", "Pulsar", "Tiida", "Leaf"],
    "SUV/Crossover": [
      "Qashqai",
      "Qashqai+2",
      "X-Trail",
      "Juke",
      "Murano",
      "Pathfinder",
      "Terrano",
      "Ariya",
    ],
    "Pickup/Truck": [
      "Navara",
      "NP 300",
      "PickUp",
      "King Cab",
      "Frontier",
      "Cabstar",
    ],
    Sedan: [
      "Almera",
      "Almera Tino",
      "Primera",
      "Maxima",
      "Sunny",
      "Bluebird",
      "Altima",
    ],
    "Sports/Coupe": [
      "GT-R",
      "Skyline",
      "200 SX",
      "300 ZX",
      "350Z",
      "370Z",
      "280 ZX",
      "Silvia",
    ],
    "MPV/Minivan": ["Serena", "Evalia", "Quest", "Prairie"],
    "Van/Commercial": [
      "NV200",
      "NV300",
      "NV400",
      "e-NV200",
      "Interstar",
      "Primastar",
      "Vanette",
      "Urvan",
      "Townstar",
      "Kubistar",
    ],
    Classic: ["100 NX"],
    Other: ["Other"],
  },
  Hyundai: {
    Популярные: ["i30", "Tucson", "i10", "i20", "Santa Fe"],
    Hatchback: ["i10", "i20", "i30", "Getz", "Bayon"],
    Sedan: ["Accent", "Elantra", "Sonata", "Azera", "Grandeur", "Lantra"],
    "SUV/Crossover": [
      "Tucson",
      "Kona",
      "Santa Fe",
      "Grand Santa Fe",
      "ix35",
      "ix55",
      "Terracan",
      "Veracruz",
      "Galloper",
      "Nexo",
    ],
    Electric: ["IONIQ", "IONIQ 5", "IONIQ 6"],
    "Coupe/Sports": ["Coupe", "S-Coupe", "Veloster", "Genesis"],
    "MPV/Minivan": ["Staria", "Trajet", "Santamo", "Matrix"],
    "Van/Commercial": ["H-1", "H-1 Starex", "H350"],
    Classic: ["Pony"],
    Other: ["Other"],
  },
  Toyota: {
    Популярные: ["Yaris", "Corolla", "RAV 4", "Hilux", "Camry"],
    Hatchback: ["Yaris", "Aygo (X)", "Auris", "Starlet", "IQ"],
    Sedan: ["Corolla", "Avensis", "Camry", "Crown", "Carina", "Avalon"],
    "SUV/Crossover": [
      "RAV 4",
      "C-HR",
      "Yaris Cross",
      "Corolla Cross",
      "Highlander",
      "Sequoia",
      "4-Runner",
      "Urban Cruiser",
      "FJ",
    ],
    "Pickup/Truck": ["Hilux", "Tacoma", "Tundra", "Dyna"],
    "Electric/Hydrogen": ["bZ4X", "Mirai"],
    "MPV/Minivan": [
      "Verso",
      "Corolla Verso",
      "Verso-S",
      "Avensis Verso",
      "Previa",
      "Sienna",
      "Alphard",
      "Picnic",
    ],
    "Van/Commercial": ["Proace (Verso)", "PROACE CITY", "Hiace"],
    "Sports/Coupe": ["Celica", "Supra", "GT86", "GR86", "MR 2", "Paseo"],
    Classic: ["Tercel"],
    Other: ["Other"],
  },
  Citroën: {
    Популярные: ["C3", "Berlingo", "C4", "C5", "Jumper"],
    Hatchback: ["C1", "C2", "C3", "C4", "C4 X", "C5 X", "SAXO", "AX", "ZX"],
    "SUV/Crossover": ["C3 Aircross", "C4 Aircross", "C5 Aircross", "C-Crosser"],
    "MPV/Minivan": [
      "C3 Picasso",
      "C4 Picasso",
      "C4 SpaceTourer",
      "Grand C4 Picasso / SpaceTourer",
      "Xsara Picasso",
      "Xsara",
      "C8",
      "Evasion",
    ],
    Sedan: ["C5", "C-Elysée", "DS", "DS4", "DS4 Crossback", "DS5", "C6"],
    Electric: ["C-Zero", "ë-Berlingo", "E-MEHARI", "AMI"],
    "Van/Commercial": ["Jumper", "Jumpy", "SpaceTourer", "Nemo"],
    Classic: ["2 CV", "CX", "BX", "XM", "Xantia", "SM", "GSA"],
    Other: ["Other"],
  },
  Peugeot: {
    Популярные: ["206", "208", "308", "3008", "2008"],
    Hatchback: [
      "106",
      "107",
      "108",
      "206",
      "207",
      "208",
      "306",
      "307",
      "308",
      "309",
    ],
    "SUV/Crossover": ["2008", "3008", "4007", "4008", "5008"],
    Sedan: ["405", "406", "407", "408", "504", "505", "605", "607"],
    Electric: ["e-208", "iOn"],
    "MPV/Minivan": ["1007", "806", "807"],
    "Van/Commercial": [
      "Boxer",
      "Partner",
      "Partner Tepee",
      "Rifter",
      "Bipper",
      "Bipper Tepee",
      "Expert",
      "Expert Tepee",
      "Traveller",
    ],
    Classic: ["104", "204", "304", "305", "404"],
    "Coupe/Sports": ["RCZ"],
    Other: ["Other"],
  },
  Kia: {
    Популярные: ["Sportage", "Picanto", "Sorento", "cee'd / Ceed", "Rio"],
    Hatchback: [
      "Picanto",
      "Rio",
      "cee'd / Ceed",
      "XCeed",
      "pro cee'd / ProCeed",
    ],
    "SUV/Crossover": [
      "Sportage",
      "Sorento",
      "Niro",
      "Stonic",
      "Soul",
      "Borrego",
    ],
    Sedan: ["Optima", "Cerato", "Magentis", "Sephia", "Mentor"],
    Electric: ["EV3", "EV6", "EV9"],
    "MPV/Minivan": ["Carens", "Carnival", "Venga", "Joice"],
    "Van/Commercial": ["K2500", "Pregio"],
    Classic: ["Opirus", "Shuma", "Pride", "Elan", "Retona"],
    Other: ["Other"],
  },
  Dacia: {
    Популярные: ["Sandero", "Duster", "Logan", "Spring", "Jogger"],
    Hatchback: ["Sandero"],
    "SUV/Crossover": ["Duster"],
    Sedan: ["Logan"],
    Electric: ["Spring"],
    "MPV/Minivan": ["Jogger", "Dokker", "Lodgy"],
    "Pickup/Truck": ["Logan Pick-Up", "Pick Up"],
    Other: ["Other"],
  },

  Porsche: {
    Популярные: ["Cayenne", "Macan", "911", "Panamera", "Taycan"],
    "SUV/Crossover": ["Cayenne", "Macan"],
    Sedan: ["Panamera", "Taycan"],
    "Coupe/Sports": [
      "911",
      "992",
      "991",
      "997",
      "996",
      "964",
      "993",
      "930",
      "968",
      "918",
      "Carrera GT",
    ],
    Classic: ["356", "914", "944", "924", "928", "912"],
    Other: ["Other"],
  },

  MINI: {
    Популярные: ["Cooper", "ONE", "Cooper S", "Cooper SE", "John Cooper Works"],
    Hatchback: [
      "Cooper",
      "ONE",
      "Cooper S",
      "Cooper SE",
      "One First",
      "1300",
      "1000",
    ],
    Electric: ["Cooper SE", "Cooper E", "Cooper E Countryman"],
    "Cabrio/Roadster": [
      "Cooper Cabrio",
      "Cooper S Cabrio",
      "Cooper D Cabrio",
      "Cooper SD Cabrio",
      "Cooper S Roadster",
      "Cooper SD Roadster",
      "John Cooper Works Cabrio",
      "John Cooper Works Roadster",
    ],
    "SUV/Crossover": [
      "Cooper Countryman",
      "Cooper S Countryman",
      "Cooper D Countryman",
      "Cooper SE Countryman",
      "John Cooper Works Countryman",
      "One Countryman",
      "One D Countryman",
    ],
    Clubman: [
      "Cooper Clubman",
      "Cooper S Clubman",
      "Cooper D Clubman",
      "Cooper SD Clubman",
      "One Clubman",
      "One D Clubman",
      "John Cooper Works Clubman",
    ],
    Paceman: [
      "Cooper Paceman",
      "Cooper S Paceman",
      "Cooper D Paceman",
      "Cooper SD Paceman",
      "John Cooper Works Paceman",
    ],
    Coupé: ["Cooper Coupé", "Cooper S Coupé", "John Cooper Works Coupé"],
    "Van/Utility": ["Clubvan"],
    Other: ["Other", "Aceman", "Cooper C", "Cooper C Countryman"],
  },
  Mazda: {
    Популярные: ["2", "CX-5", "3", "6", "MX-5"],
    "Hatchback/Sedan": [
      "2",
      "2 Hybrid",
      "3",
      "6",
      "323",
      "626",
      "929",
      "Xedos",
      "Demio",
    ],
    "SUV/Crossover": [
      "CX-3",
      "CX-5",
      "CX-7",
      "CX-9",
      "CX-30",
      "CX-60",
      "CX-80",
    ],
    "Sports/Coupe": ["MX-3", "MX-5", "MX-6", "RX-6", "RX-7", "RX-8"],
    "MPV/Minivan": ["MPV", "Premacy"],
    "Pickup/Truck": ["BT-50", "B series"],
    "Van/Commercial": ["E series"],
    Other: ["Other", "Tribute"],
  },

  Jeep: {
    Популярные: [
      "Renegade",
      "Compass",
      "Grand Cherokee",
      "Wrangler",
      "Cherokee",
    ],
    "SUV/Crossover": [
      "Renegade",
      "Compass",
      "Grand Cherokee",
      "Cherokee",
      "Avenger",
    ],
    "Off-road": ["Wrangler", "CJ", "Willys", "Gladiator"],
    "Pickup/Truck": ["Comanche"],
    Other: ["Other", "Commander", "Wagoneer", "Patriot"],
  },

  Cupra: {
    Популярные: ["Formentor", "Leon", "Ateca", "Born", "Tavascan"],
    "Hatchback/Sedan": ["Leon", "Born"],
    "SUV/Crossover": ["Formentor", "Ateca", "Terramar", "Tavascan"],
    Other: ["Other"],
  },

  Volvo: {
    Популярные: ["XC60", "XC90", "V60", "XC40", "V40"],
    Sedan: ["S40", "S60", "S70", "S80", "S90"],
    Hatchback: ["V40", "C30", "C70"],
    "SUV/Crossover": [
      "XC40",
      "XC60",
      "XC70",
      "XC90",
      "EX30",
      "EX40",
      "EX90",
      "C40",
    ],
    "Estate/Wagon": [
      "V50",
      "V60",
      "V60 Cross Country",
      "V70",
      "V90",
      "V90 Cross Country",
    ],
    Classic: [
      "240",
      "244",
      "245",
      "340",
      "360",
      "440",
      "460",
      "480",
      "740",
      "745",
      "760",
      "780",
      "850",
      "940",
      "944",
      "945",
      "960",
    ],
    Other: ["Other"],
  },
  Lancia: {
    Популярные: ["Ypsilon", "MUSA", "Delta", "Thema", "Voyager"],
    "Hatchback/Sedan": ["Ypsilon", "Delta", "Lybra", "Prisma", "Dedra"],
    "Luxury/Sedan": ["Thema", "Thesis", "Kappa", "Flavia", "Flaminia", "Gamma"],
    "MPV/Minivan": ["MUSA", "Phedra", "Zeta", "Voyager"],
    Classic: ["Beta", "Fulvia"],
    Other: ["Other"],
  },

  Honda: {
    Популярные: ["Civic", "Jazz", "CR-V", "Accord", "HR-V"],
    "Hatchback/Sedan": [
      "Civic",
      "Jazz",
      "Accord",
      "City",
      "Concerto",
      "Insight",
    ],
    "SUV/Crossover": ["CR-V", "HR-V", "ZR-V", "e:Ny1", "Pilot"],
    Electric: ["e"],
    "Sports/Coupe": ["CRX", "Prelude", "CR-Z", "S2000", "NSX", "Integra"],
    "MPV/Minivan": ["FR-V", "Stream", "Shuttle", "Odyssey"],
    "Pickup/Truck": ["Ridgeline"],
    Other: ["Other", "Aerodeck", "Legend"],
  },

  Suzuki: {
    Популярные: ["Swift", "Jimny", "Vitara", "Ignis", "SX4"],
    Hatchback: ["Swift", "Alto", "Splash", "Celerio", "Baleno"],
    "SUV/Crossover": [
      "Vitara",
      "Grand Vitara",
      "(SX4) S-Cross",
      "Across",
      "Jimny",
    ],
    "Electric/Hybrid": ["Swace"],
    "MPV/Minivan": ["Wagon R+"],
    "Pickup/Truck": ["Super-Carry", "Carry"],
    "Classic/Off-road": ["SJ Samurai", "LJ", "X-90"],
    "Sports/Coupe": ["Kizashi", "Cappuccino"],
    Other: ["Other"],
  },

  Mitsubishi: {
    Популярные: ["Space Star", "Colt", "Outlander", "ASX", "Pajero"],
    "Hatchback/Sedan": ["Colt", "Lancer", "Galant", "Diamante"],
    "SUV/Crossover": [
      "Outlander",
      "ASX",
      "Eclipse Cross",
      "Pajero",
      "Pajero Pinin",
      "Montero",
    ],
    "Electric/Hybrid": ["i-MiEV", "Plug-in Hybrid Outlander"],
    "MPV/Minivan": [
      "Space Star",
      "Grandis",
      "Space Wagon",
      "Space Runner",
      "Space Gear",
    ],
    "Pickup/Truck": ["L200", "Pick-up", "Canter"],
    "Classic/Sports": ["Eclipse", "3000 GT"],
    "Van/Commercial": ["L300", "L400"],
    Other: ["Other"],
  },
  "Land Rover": {
    Популярные: [
      "Range Rover Evoque",
      "Range Rover Sport",
      "Discovery Sport",
      "Defender",
      "Range Rover",
    ],
    SUV: [
      "Range Rover",
      "Range Rover Evoque",
      "Range Rover Sport",
      "Range Rover Velar",
      "Discovery",
      "Discovery Sport",
      "Freelander",
      "Defender",
    ],
    Classic: ["Serie I", "Serie II", "Serie III"],
    Other: ["Other"],
  },

  "Alfa Romeo": {
    Популярные: ["Stelvio", "Giulietta", "MiTo", "Giulia", "Tonale"],
    "Sedan/Hatchback": [
      "Giulietta",
      "MiTo",
      "Giulia",
      "Alfa 159",
      "Alfa 147",
      "Alfa 156",
      "Alfa 166",
      "Alfa 164",
      "Alfa 155",
      "Alfa 33",
      "Alfa 75",
      "Alfa 146",
      "Alfa 145",
    ],
    "SUV/Crossover": ["Stelvio", "Tonale"],
    "Sports/Coupe": ["Spider", "GT", "GTV", "Brera", "4C", "Sprint", "Alfasud"],
    Classic: ["Alfetta", "Junior"],
    Other: ["Other"],
  },

  Tesla: {
    Популярные: ["Model 3", "Model Y", "Model S", "Model X"],
    Electric: ["Model 3", "Model Y", "Model S", "Model X"],
    Other: ["Other"],
  },

  Chevrolet: {
    Популярные: ["Captiva", "Spark", "Camaro", "Cruze", "Tahoe"],
    "Hatchback/Sedan": [
      "Matiz",
      "Spark",
      "Aveo",
      "Kalos",
      "Nubira",
      "Epica",
      "Lacetti",
      "Impala",
      "Rezzo",
      "Malibu",
      "Cavalier",
      "Lumina",
    ],
    "SUV/Crossover": [
      "Captiva",
      "Blazer",
      "Tahoe",
      "Trailblazer",
      "Suburban",
      "Traverse",
    ],
    "Pickup/Truck": ["Silverado", "C1500", "S-10", "K30", "K1500", "Colorado"],
    "Van/Commercial": ["Chevy Van", "Express", "Astro"],
    "Sports/Coupe": [
      "Camaro",
      "Corvette",
      "SSR",
      "Chevelle",
      "El Camino",
      "Beretta",
    ],
    Classic: ["Alero", "Evanda"],
    Other: ["Other", "G", "Avalanche", "Niva"],
  },

  Subaru: {
    Популярные: ["Forester", "Impreza", "XV", "Legacy", "Outback"],
    "Hatchback/Sedan": ["Impreza", "Legacy"],
    "SUV/Crossover": ["Forester", "XV", "Outback", "Crosstrek", "Levorg"],
    Electric: ["Solterra"],
    "Sports/Coupe": ["WRX STI", "BRZ", "SVX", "XT"],
    "MPV/Minivan": ["Trezia"],
    Classic: ["Justy", "Vivio", "Libero"],
    Other: ["Other", "B9 Tribeca", "Tribeca"],
  },
  Jaguar: {
    Популярные: ["XF", "F-Pace", "E-Pace", "XE", "F-Type"],
    "Hatchback/Sedan": [
      "XE",
      "XF",
      "X-Type",
      "S-Type",
      "XJ",
      "XJ6",
      "XJ8",
      "XJ12",
      "XJ40",
      "XJR",
    ],
    "SUV/Crossover": ["F-Pace", "E-Pace", "I-Pace"],
    "Sports/Coupe": ["F-Type", "XK", "XK8", "XKR", "XJS", "E-Type"],
    Classic: ["Daimler", "MK II", "XJSC"],
    Other: ["Other"],
  },

  Dodge: {
    Популярные: ["RAM", "Challenger", "Durango", "Charger", "Dakota"],
    "Pickup/Truck": ["RAM", "Dakota"],
    "SUV/Crossover": ["Durango", "Nitro", "Journey"],
    "Sports/Coupe": ["Challenger", "Viper", "Demon", "Stealth"],
    Sedan: ["Charger", "Avenger", "Dart", "Magnum"],
    Classic: ["Coronet"],
    Other: ["Other"],
  },

  Trabant: {
    Популярные: ["601"],
    Classic: ["601"],
    Other: ["Other"],
  },

  Iveco: {
    Популярные: ["Massif"],
    "SUV/Off-road": ["Massif"],
    Other: ["Other"],
  },

  Chrysler: {
    Популярные: ["300C", "Voyager", "Grand Voyager", "PT Cruiser", "Pacifica"],
    Sedan: [
      "300C",
      "200",
      "300 M",
      "Neon",
      "Stratus",
      "Le Baron",
      "New Yorker",
      "Imperial",
      "Vision",
    ],
    "SUV/Crossover": ["Aspen"],
    "MPV/Minivan": ["Voyager", "Grand Voyager", "Pacifica"],
    "Sports/Coupe": ["Crossfire", "Viper", "Daytona"],
    Classic: ["ES"],
    Other: ["Other"],
  },

  Abarth: {
    Популярные: ["595", "500", "695", "595 Turismo", "595 Competizione"],
    Hatchback: [
      "500",
      "595",
      "595 Turismo",
      "595 Competizione",
      "595C",
      "695",
      "695C",
    ],
    Convertible: ["500C", "695C"],
    Sports: ["124 Spider"],
    Other: ["Other", "Grande Punto", "Punto Evo"],
  },

  Saab: {
    Популярные: ["9-3", "9-5", "900", "9000", "9-7X"],
    "Sedan/Hatchback": ["9-3", "9-5", "900", "9000"],
    SUV: ["9-7X"],
    Classic: ["96"],
    Other: ["Other"],
  },

  Daihatsu: {
    Популярные: ["Sirion", "Cuore", "Terios", "Charade", "Copen"],
    Hatchback: [
      "Sirion",
      "Cuore",
      "Charade",
      "Move",
      "MATERIA",
      "TREVIS",
      "YRV",
    ],
    "SUV/Crossover": ["Terios", "Feroza/Sportrak", "Freeclimber"],
    "Van/Commercial": ["Hijet"],
    Sports: ["Copen"],
    Other: ["Other", "Applause"],
  },

  Lada: {
    Популярные: ["Niva", "Vesta", "Kalina", "Samara", "Granta"],
    "Hatchback/Sedan": [
      "Vesta",
      "Kalina",
      "Samara",
      "Priora",
      "Nova",
      "Granta",
      "2107",
    ],
    "SUV/Off-road": ["Niva", "Taiga", "Urban"],
    Classic: ["1200", "Aleko", "111", "2111"],
    Other: ["Other"],
  },

  "DS Automobiles": {
    Популярные: ["DS7 (Crossback)", "DS3", "DS4", "DS5", "DS9"],
    "SUV/Crossover": ["DS7 (Crossback)", "DS3 Crossback"],
    "Hatchback/Sedan": ["DS3", "DS4", "DS5", "DS9"],
    Other: ["Other", "DS4 Crossback"],
  },
  Maserati: {
    Популярные: ["Ghibli", "Levante", "Quattroporte", "Grecale", "Granturismo"],
    Sedan: ["Ghibli", "Quattroporte"],
    SUV: ["Levante", "Grecale"],
    "Sports/Coupe": [
      "Granturismo",
      "GranCabrio",
      "MC20",
      "Gransport",
      "Spyder",
    ],
    Classic: ["4200", "3200", "Biturbo", "Merak", "222", "Indy"],
    Other: ["Other"],
  },

  MG: {
    Популярные: ["EHS", "ZS", "HS", "MG4", "MG3"],
    "Hatchback/Sedan": ["MG3", "MG4", "MG5"],
    SUV: ["ZS", "HS", "EHS", "Marvel R"],
    Classic: ["MGA", "MGB", "Midget", "TF", "TD", "ZR", "MGF"],
    Other: ["Other"],
  },

  Lamborghini: {
    Популярные: ["Urus", "Huracán", "Aventador", "Gallardo", "Revuelto"],
    SUV: ["Urus", "LM"],
    Supercars: ["Huracán", "Aventador", "Gallardo", "Revuelto", "Murciélago"],
    Classic: ["Espada", "Jalpa"],
    Other: ["Other"],
  },

  Lexus: {
    Популярные: ["UX", "NX 350", "RX 450", "IS 300", "LC 500"],
    "SUV/Crossover": [
      "UX",
      "NX 200",
      "NX 300",
      "NX 350",
      "NX 450",
      "RX 300",
      "RX 350",
      "RX 400",
      "RX 450",
      "RX 500",
      "RZ",
      "GX 460",
      "LX 500",
      "LX 570",
      "LX 600",
    ],
    Sedan: [
      "IS 200",
      "IS 220",
      "IS 250",
      "IS 300",
      "IS 350",
      "IS-F",
      "GS 250",
      "GS 300",
      "GS 350",
      "GS 430",
      "GS 450",
      "ES 300",
      "LS 400",
      "LS 430",
      "LS 460",
      "LS 500",
    ],
    "Sports/Coupe": [
      "LC 500",
      "LC 500h",
      "RC 200",
      "RC 300",
      "RC F",
      "SC 400",
      "SC 430",
    ],
    Other: ["Other", "LBX", "LM"],
  },

  Aixam: {
    Популярные: ["City"],
    Microcar: ["City", "Crossline"],
    Other: ["Other"],
  },

  "Lynk&Co": {
    Популярные: ["01"],
    SUV: ["01"],
    Other: ["Other"],
  },

  Isuzu: {
    Популярные: ["D-Max"],
    "Pickup/Truck": ["D-Max", "PICK UP", "Campo"],
    "SUV/Off-road": ["Trooper"],
    Other: ["Other"],
  },

  Bentley: {
    Популярные: [
      "Continental GT",
      "Bentayga",
      "Flying Spur",
      "Mulsanne",
      "Arnage",
    ],
    SUV: ["Bentayga"],
    Sedan: [
      "Flying Spur",
      "Continental Flying Spur",
      "Mulsanne",
      "Arnage",
      "Brooklands",
    ],
    "Coupe/Convertible": [
      "Continental GT",
      "Continental GTC",
      "Continental Supersports",
      "Azure",
    ],
    Classic: ["Turbo R", "S2"],
    Other: ["Other", "Continental"],
  },

  Rover: {
    Популярные: ["75", "200", "45", "25", "400"],
    "Hatchback/Sedan": ["75", "200", "25", "45", "400", "600", "620", "820"],
    Classic: ["100", "213", "216", "416", "SD"],
    Other: ["Other"],
  },

  MAN: {
    Популярные: ["TGE"],
    Commercial: ["TGE"],
    Other: ["Other"],
  },

  Ssangyong: {
    Популярные: ["REXTON", "Korando", "Tivoli", "Kyron", "MUSSO"],
    "SUV/Crossover": ["REXTON", "Korando", "Tivoli", "Torres"],
    "Pickup/Truck": ["MUSSO"],
    "MPV/Minivan": ["Rodius"],
    Other: ["Other", "Actyon", "XLV"],
  },

  Cadillac: {
    Популярные: ["XT4", "Escalade", "CTS", "Deville", "Seville"],
    SUV: ["XT4", "XT5", "Escalade", "SRX"],
    Sedan: ["CTS", "ATS", "Seville", "Deville"],
    "Luxury/Coupe": ["XLR", "Eldorado", "Fleetwood"],
    Other: ["Other"],
  },
  Polestar: ["2", "Other", "1"],
  Ferrari: {
    Популярные: ["812", "SF90", "F8", "Roma", "Portofino"],
    "Coupe/Supercar": [
      "SF90",
      "F8",
      "F430",
      "488 GTB",
      "488 Pista",
      "458",
      "360",
      "599 GTB",
      "Testarossa",
      "LaFerrari",
      "Enzo Ferrari",
    ],
    Convertible: [
      "California",
      "Portofino",
      "488 Spider",
      "296 GTS",
      "Superamerica",
    ],
    "Grand Tourer": ["GTC4Lusso", "FF", "F12", "612"],
    Classic: [
      "250",
      "330",
      "365",
      "400",
      "208",
      "308",
      "328",
      "348",
      "512",
      "Dino GT4",
      "Mondial",
      "456",
      "575",
      "550",
      "Daytona",
      "Monza",
    ],
    Other: ["Other", "Purosangue", "296 GTB"],
  },

  Alpine: {
    Популярные: ["A110"],
    "Coupe/Sports": ["A110", "A290"],
  },

  "Aston Martin": {
    Популярные: ["V8 Vantage", "DBX", "DBS", "DB11", "DB12"],
    SUV: ["DBX"],
    "Coupe/Sports": [
      "V8 Vantage",
      "V12 Vantage",
      "Vanquish",
      "DBS",
      "DB11",
      "DB12",
    ],
    Sedan: ["DB7", "DB9", "Rapide", "DB"],
    Classic: ["Lagonda", "Virage", "Cygnet"],
    Other: ["Other"],
  },

  Daewoo: {
    Популярные: ["Matiz", "Kalos", "Lanos", "Nubira", "Lacetti"],
    "Hatchback/Sedan": [
      "Matiz",
      "Kalos",
      "Lanos",
      "Nubira",
      "Lacetti",
      "Evanda",
      "Espero",
    ],
    "MPV/Minivan": ["Rezzo"],
    Other: ["Other"],
  },

  Ligier: {
    Популярные: ["JS 50", "JS 60"],
    Microcar: ["JS 50", "JS 50 L", "JS 60", "X - Too", "Ambra", "Myli"],
    Other: ["Other"],
  },

  Hummer: {
    Популярные: ["H2", "H3", "H1"],
    "SUV/Off-road": ["H1", "H2", "H3"],
  },

  GWM: {
    Популярные: ["Ora 03", "Wey 05"],
    "Electric/SUV": ["Ora 03", "Wey 05", "Wey 03"],
  },

  Corvette: {
    Популярные: ["C8", "C7", "C6", "C5", "ZR 1"],
    "Coupe/Sports": [
      "C1",
      "C2",
      "C3",
      "C4",
      "C5",
      "C6",
      "C7",
      "C8",
      "Z06",
      "ZR 1",
    ],
  },

  "Rolls-Royce": {
    Популярные: ["Cullinan", "Phantom", "Spectre", "Ghost", "Wraith"],
    SUV: ["Cullinan"],
    "Luxury Sedan": [
      "Phantom",
      "Ghost",
      "Silver Shadow",
      "Silver Spirit",
      "Silver Spur",
    ],
    Convertible: ["Corniche", "Dawn"],
    Classic: ["Silver Cloud"],
    Other: ["Other"],
  },

  SWM: {
    Популярные: ["G01"],
    SUV: ["G01"],
    Other: ["Other"],
  },

  ALPINA: {
    Популярные: ["B3", "D3", "B5", "B7", "XD3"],
    Sedan: ["B3", "D3", "D3 S", "B5", "D5", "B7"],
    SUV: ["XD3", "XD4", "XB7"],
    "Coupe/Sports": ["B4", "B6", "B8", "D4", "D4 S", "B10"],
    Other: ["Other"],
  },
  Infiniti: {
    Популярные: ["Q30", "Q50", "Q70", "QX70", "QX80"],
    "Hatchback/Sedan": ["Q30", "Q50", "Q70", "M30", "G37"],
    "SUV/Crossover": ["FX", "QX30", "QX70", "QX80"],
    Other: ["Other"],
  },

  Piaggio: {
    Популярные: ["Porter"],
    Commercial: ["Porter", "APE", "APE TM"],
    Other: ["Other"],
  },

  Maxus: {
    Популярные: ["Deliver 9", "eDeliver 3", "Euniq 6"],
    "Van/Commercial": ["Deliver 9", "eDeliver 3"],
    "SUV/Electric": ["Euniq 5", "Euniq 6", "Mifa 9"],
    "Pickup/Truck": ["T90"],
    Other: ["Other"],
  },

  BAIC: {
    Популярные: ["X55", "BJ40", "Beijing X75"],
    "SUV/Crossover": ["X55", "BJ20", "BJ40", "Beijing X75"],
    Hatchback: ["Senova X25", "Senova X35", "Senova X55"],
    Other: ["Other"],
  },

  "Austin Healey": {
    Other: ["Other"],
  },

  DFSK: {
    Популярные: ["Fengon 500", "Fengon 5", "Fengon 580"],
    "SUV/Crossover": [
      "Fengon 500",
      "Fengon 5",
      "Fengon 7",
      "Fengon 580",
      "Fengon 600",
      "Fengon",
    ],
    Electric: ["Seres 3"],
    "Pickup/Truck": ["K01", "Rich 6"],
    MPV: ["Forthing 4", "Forthing 5"],
    Other: ["Other", "Glory"],
  },

  INEOS: {
    Популярные: ["Grenadier"],
    "SUV/Off-road": ["Grenadier"],
  },

  GMC: {
    Популярные: ["Sierra", "Yukon", "Savana"],
    "Pickup/Truck": ["Sierra"],
    SUV: ["Yukon"],
    "Van/Commercial": ["Savana", "Safari"],
    Other: ["Other"],
  },

  BYD: {
    Популярные: ["ATTO 3", "SEAL", "DOLPHIN", "TANG"],
    Electric: ["ATTO 3", "SEAL", "DOLPHIN", "Seal U", "HAN"],
    SUV: ["TANG"],
    Other: ["Other"],
  },

  Lotus: {
    Популярные: ["Elise", "Exige", "Esprit", "Emira", "Eletre"],
    "Coupe/Sports": ["Elise", "Exige", "Esprit", "Emira", "Evora"],
    Electric: ["Eletre"],
    Classic: ["Elan", "Super Seven", "Europa", "340 R"],
    Other: ["Other"],
  },

  McLaren: {
    Популярные: ["720S", "570S", "GT", "Artura", "750S"],
    Supercars: ["720S", "570S", "600LT", "620R", "750S", "765LT", "MP4-12C"],
    "GT/Coupe": ["570GT", "GT"],
    Convertible: ["650S", "650S Coupé", "650S Spider"],
    Other: ["Other"],
  },
  AC: ["Other"],
  Leapmotor: ["T03", "C10"],
  Plymouth: ["Other", "Prowler"],
  Pontiac: [
    "Firebird",
    "Trans Am",
    "Bonneville",
    "Fiero",
    "Other",
    "GTO",
    "Solstice",
  ],
  Triumph: ["Spitfire", "TR6", "TR4", "Other", "TR3"],
  Genesis: ["G70", "GV70", "GV80", "G80", "GV60"],
  Morgan: ["Plus 4", "Plus 6", "4/4", "Plus 8", "Other", "3 Wheeler"],
  ORA: ["Funky Cat", "Other"],
  Wartburg: ["353", "Other", "311"],
  Elaris: ["Other"],
  Lincoln: ["Continental", "Town Car", "Navigator", "Other"],
  Microcar: ["M.Go", "DUÈ", "Other", "MC1", "MC2", "M-8", "Flex"],
  NSU: ["Other"],
  Tata: ["Xenon", "Other", "Indica"],
  Borgward: ["Other"],
  Aiways: ["U5"],
  XPENG: ["G9", "P7", "Other"],
  Maybach: ["Other", "S650", "57", "62"],
  JAC: ["8 Pro", "Other"],
  Oldsmobile: ["Other", "Toronado", "Delta 88", "Cutlass", "Bravada"],
  Seres: ["3", "5"],
  Cobra: ["Other"],
  Austin: ["Other"],
  Chatenet: ["Other"],
  "e.GO": ["Other", "Life"],
  DeTomaso: ["Pantera"],
  Barkas: ["B1000", "Other"],
  Bugatti: ["Other", "Chiron"],
  Buick: ["Other", "Skylark", "Roadmaster", "Enclave", "Electra", "Century"],
  XEV: ["Other", "YOYO"],
  Fisker: ["Karma", "Ocean"],
  Donkervoort: ["S8", "D8", "Other"],
  TECHART: ["Other"],
  Westfalia: [],
  Casalini: ["Other"],
  NIO: ["EL7", "ET5"],
  Westfield: ["Other"],
  Grecav: ["Sonique"],
  Lucid: ["Air"],
  Microlino: ["Dolce", "Competizione", "Pioneer"],
  WEY: ["Coffee 01", "Coffee 02"],
  TVR: ["Other", "Tuscan"],
  LEVC: ["Other"],
  Wiesmann: ["MF 3", "MF 4", "Other", "MF 5"],
  Brilliance: ["BS6", "BS4", "BS2"],
  Acura: ["RSX", "NSX"],
  Talbot: ["Samba"],
  Zhidou: ["Other"],
  "Asia Motors": ["Rocsta"],
};

const cityTranslations: Record<string, string> = {
  Берлин: "Berlin",
  Гамбург: "Hamburg",
  Мюнхен: "Munich",
  Кёльн: "Cologne",
  Франкфурт: "Frankfurt",
  Штутгарт: "Stuttgart",
  Дюссельдорф: "Düsseldorf",
  Дортмунд: "Dortmund",
  Эссен: "Essen",
  Бремен: "Bremen",
  Париж: "Paris",
  Марсель: "Marseille",
  Лион: "Lyon",
  Тулуза: "Toulouse",
  Ницца: "Nice",
  Нант: "Nantes",
  Страсбург: "Strasbourg",
  Монпелье: "Montpellier",
  Бордо: "Bordeaux",
  Лилль: "Lille",
  Рим: "Rome",
  Милан: "Milan",
  Неаполь: "Naples",
  Турин: "Turin",
  Палермо: "Palermo",
  Генуя: "Genoa",
  Болонья: "Bologna",
  Флоренция: "Florence",
  Венеция: "Venice",
  Верона: "Verona",
  Мадрид: "Madrid",
  Барселона: "Barcelona",
  Валенсия: "Valencia",
  Севилья: "Seville",
  Сарагоса: "Zaragoza",
  Малага: "Malaga",
  Мурсия: "Murcia",
  "Пальма-де-Майорка": "Palma de Mallorca",
  Бильбао: "Bilbao",
  Аликанте: "Alicante",
  Лондон: "London",
  Бирмингем: "Birmingham",
  Манчестер: "Manchester",
  Ливерпуль: "Liverpool",
  Лидс: "Leeds",
  Шеффилд: "Sheffield",
  Эдинбург: "Edinburgh",
  Глазго: "Glasgow",
  Бристоль: "Bristol",
  Кардифф: "Cardiff",
  Варшава: "Warsaw",
  Краков: "Krakow",
  Лодзь: "Łódź",
  Вроцлав: "Wrocław",
  Познань: "Poznań",
  Гданьск: "Gdańsk",
  Щецин: "Szczecin",
  Люблин: "Lublin",
  Быдгощ: "Bydgoszcz",
  Катовице: "Katowice",
  Амстердам: "Amsterdam",
  Роттердам: "Rotterdam",
  Гаага: "The Hague",
  Утрехт: "Utrecht",
  Эйндховен: "Eindhoven",
  Гронинген: "Groningen",
  Бреда: "Breda",
  Неймеген: "Nijmegen",
  Алкмар: "Alkmaar",
  Маастрихт: "Maastricht",
  Прага: "Prague",
  Брно: "Brno",
  Острава: "Ostrava",
  Пльзень: "Plzeň",
  Либерец: "Liberec",
  Оломоуц: "Olomouc",
  "Ческе-Будеевице": "České Budějovice",
  "Градец-Кралове": "Hradec Králové",
  "Усти-над-Лабем": "Ústí nad Labem",
  Пардубице: "Pardubice",
  Вена: "Vienna",
  Грац: "Graz",
  Линц: "Linz",
  Зальцбург: "Salzburg",
  Инсбрук: "Innsbruck",
  Клагенфурт: "Klagenfurt",
  Велс: "Wels",
  Штайр: "Steyr",
  "Санкт-Пельтен": "Sankt Pölten",
  Фельдкирх: "Feldkirch",
  Стокгольм: "Stockholm",
  Гётеборг: "Gothenburg",
  Мальмё: "Malmö",
  Уппсала: "Uppsala",
  Вестерос: "Västerås",
  Эребру: "Örebro",
  Линчёпинг: "Linköping",
  Хельсингборг: "Helsingborg",
  Норрчёпинг: "Norrköping",
  Йёнчёпинг: "Jönköping",
};

export const countryCodeToName: { [code: string]: string } = {
  DE: "Germany",
  IT: "Italy",
  LT: "Lithuania",
  CZ: "Czech Republic",
  ES: "Spain",
  EE: "Estonia",
  FR: "France",
  SE: "Sweden",
  SK: "Slovakia",
  UA: "Ukraine",
  RS: "Serbia",
  HR: "Croatia",
  FI: "Finland",
  GR: "Greece",
  AE: "United Arab Emirates",
  MC: "Monaco",
  US: "United States",
  BA: "Bosnia and Herzegovina",
  BG: "Bulgaria",
  SI: "Slovenia",
  ME: "Montenegro",
  AD: "Andorra",
  AL: "Albania",
  TR: "Turkey",
};
export const nameToCountryCode: { [name: string]: string } = Object.keys(
  countryCodeToName
).reduce((acc, code) => {
  const countryName = countryCodeToName[code];
  acc[countryName] = code;
  return acc;
}, {} as { [name: string]: string });

const sellers: Record<string, string> = {
  Частные: "private",
  Диллеры: "dealer",
};

export {
  users,
  lastAdded,
  bodyTypes,
  transmissions,
  fuelTypes,
  driveTypes,
  radius,
  types,
  cityTranslations,
  sellers,
};
